<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12"></div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li class="active">الرئيسية</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div
                            v-if="type != 'user'"
                            class="col-lg-3 col-md-6 col-sm-6"
                        >
                            <div class="ui-item bg-success">
                                <div class="left">
                                    <h4>{{ statistics.activeProps }}</h4>
                                    <p>الإعلانات المفعلة</p>
                                </div>
                                <div class="right">
                                    <i class="la la-map-marker"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="ui-item bg-warning">
                                <div class="left">
                                    <h4>{{ statistics.bookings }}</h4>
                                    <p>الحجوزات</p>
                                </div>
                                <div class="right">
                                    <i class="la la-eye"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="ui-item bg-active">
                                <div class="left">
                                    <h4>{{ statistics.reviews }}</h4>
                                    <p>التقييمات</p>
                                </div>
                                <div class="right">
                                    <i class="la la-comments-o"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="ui-item bg-dark">
                                <div class="left">
                                    <h4>{{ statistics.favourites }}</h4>
                                    <p>المفضلة</p>
                                </div>
                                <div class="right">
                                    <i class="la la-heart-o"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "dashboard",
    data() {
        return {
            statistics: {
                favourites: 0,
                bookings: 0,
                reviews: 0,
                activeProps: 0,
            },
        };
    },
    mounted() {
        this.$axios.get("/my/dashboard").then((response) => {
            Object.assign(this.statistics, response.data);
        });
    },
    computed: {
        type() {
            return this.$store.state.userData.type;
        },
    },
};
</script>